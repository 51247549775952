
import classnames from 'classnames'
import React from 'react'
import { Container } from 'react-bootstrap'
import jj1_bg from '../../resources/images/jj1_bg.png'
import jj2_bg from '../../resources/images/jj2_bg.png'
import jj3_bg from '../../resources/images/jj3_bg.png'
import jj1 from '../../resources/images/jj1.png'
import jj2 from '../../resources/images/jj2.png'
import jj3 from '../../resources/images/jj3.png'
import title_bottom_icon from '../../resources/images/title_bottom_icon.png'
import lj1 from '../../resources/images/lj1.png'
import lj2 from '../../resources/images/lj2.png'
import lj3 from '../../resources/images/lj3.png'
import lj4 from '../../resources/images/lj4.png'

import './body.css'
import { useTranslation, getI18n } from 'react-i18next'
import { useContext } from 'react'
import StoreContext from '../store';
const BodyContext = () => {
    const { t } = useTranslation()
    const { language } = getI18n()
    const isTHLanguage = language == 'th'
    const isMXLanguage = language == 'mx'
    const store: any = useContext(StoreContext)
    return <>
        <Container fluid={true} className='p-t-58 px-0' id='home'>
            <div className='maxboxxsix' id='home'>
                <div className='maxbox' >
                    <div className='box_six' >
                        <div className='zg_txt_six'>
                            {t('专业广告代运营投放平台')}
                        </div>
                        <div className='bottom_txt'>{t('精准投放  免费定制方案  一站式代理')}</div>
                        <div className='bottom_txt2'>{t('助你轻松实现商业目标')}</div>
                        <div className='bt_ljty'>{t('立即体验')}</div>
                    </div>

                </div>
            </div>
            <div className='conten_box'>

                <div className='title_txt'>{t('公司简介')}</div>
                <img className='title_bottom_icon' src={title_bottom_icon} />
                <div className='conten_txt_box'>
                    <div className='content_txt'>{t('专注为国内外客户提供海外广告投放服务，在Facebook、Google、Tiktok以及Apple Search等海外广告媒体渠道及其附属的相应互联网络平台投放广告。')}</div>
                    <div className='content_txt'>{t('提供互联网游戏开发、经营业务系统开发等互联网技术软件开发服务及信息技术咨询服务。')}</div>
                    <div className='neirong_box'>
                        <div className='list_box'>
                            <img className='jj1_bg' src={jj1_bg} />
                            <div className='jj1_bottom_box'>
                                <img className='jj1' src={jj1} />
                                <div className='jj1_txt'>{t('企业愿景')}</div>
                                <div className='jj2_txt'>{t('全球广告运营投放引领者')}</div>
                            </div>

                        </div>
                        <div className='list_box'>
                            <img className='jj1_bg' src={jj2_bg} />
                            <div className='jj2_bottom_box'>
                                <img className='jj1' src={jj2} />
                                <div className='jj1_txt'>{t('我们的使命')}</div>
                                <div className='jj2_txt'>{t('为万千用户提供流量变现支持服务')}</div>
                            </div>

                        </div>
                        <div className='list_box'>
                            <img className='jj1_bg' src={jj3_bg} />
                            <div className='jj3_bottom_box'>
                                <img className='jj1' src={jj3} />
                                <div className='jj1_txt'>{t('发展目标')}</div>
                                <div className='jj2_txt'>{t('打造数据化广告流平台，实现低成本获客')}</div>
                            </div>

                        </div>
                    </div>
                    <div className='title_txt'>{t('了解我们')}</div>
                    <img className='title_bottom_icon' src={title_bottom_icon} />
                    <div className='ljwm_txt'>{t('把您的广告展现给精准用户')}</div>
                    <div className='ljwm_box'>
                        <div className='ljwm_list'>
                            <img className='ljwm_icon' src={lj1} />
                            <div className='content_bottom_txt'> {t('专业团队规模')}</div>
                            <div className='bottom_bottom_txt'>{t('1000+')}</div>
                        </div>
                        <div className='ljwm_list'>
                            <img className='ljwm_icon' src={lj2} />
                            <div className='content_bottom_txt'>{t('累计服务用户')}</div>
                            <div className='bottom_bottom_txt'>{t('10w+')}</div>
                        </div>
                        <div className='ljwm_list'>
                            <img className='ljwm_icon' src={lj3} />
                            <div className='content_bottom_txt'>{t('覆盖行业')}</div>
                            <div className='bottom_bottom_txt'>{t('100+')}</div>
                        </div>
                        <div className='ljwm_list'>
                            <img className='ljwm_icon' src={lj4} />
                            <div className='content_bottom_txt'>{t('深耕广告投放')}</div>
                            <div className='bottom_bottom_txt'>{t('10年+')}</div>
                        </div>
                    </div>
                    <div className='six_bottom_bg'>
                        <div className='six_bottom_1'>{t('行动号召')}</div>
                        <div className='six_bottom_2'>{t('关于我们，您可以用更方便的方式联系。希望能帮到您！')}</div>
                        <div className='six_bottom_3'>{t('联系我们')}</div>
                    </div>
                    <div className='title_txt'>{t('办公环境')}</div>
                    <img className='title_bottom_icon' src={title_bottom_icon} />
                    <div className='ljwm_txt'>{t('我们是最好的')}</div>
                    <div className='bg_bg'></div>
                </div>

            </div>


        </Container>
    </>
}
export default BodyContext