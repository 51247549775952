export default {
    '首页': 'หน้าแรก',
    '贷款分期': 'งวดเงินกู้',
    '常见问题': 'ปัญหาที่พบบ่อย',
    '联系我们': 'ติดต่อเรา',
    '金融信贷服务': 'บริการสินเชื่อทางการเงิน',
    '用钱就找': 'หาเงินที่นี่',
    '速度快': "ความเร็วสูง",
    '额度高': "วงเงินกู้สูง",
    '操作简单': "ง่ายต่อการใช้",
    '品牌强': "แบรนด์นี้มาแรง",
    '最高可借额度': 'วงเงินกู้สูงสุด',
    '30秒审批，3分钟到账': 'อนุมัติ 30 วินาที，เงินเข้าภายใน 3 นาที',
    '手机即可申请，还款灵活操作': 'สมัครผ่านมือถือได้ และดำเนินการชำระคืนได้อย่างคล่องตัว',
    '权威品牌，安全透明': 'แบรนด์เผด็จการ ปลอดภัย',
    '借款步骤': 'ขั้นตอนการยืม',
    '打开App': 'เปิดApp',
    '获得额度': 'รับจำนวนเงินกู้',
    '实时到账': 'เงินเข้าทันที',
    'APP首页点击“我要申请”': 'คลิก "ฉันต้องการสมัคร" บนหน้าแรกของ" APP',
    '填写信息获取额度点击“我要贷款”': 'ระบุข้อมูลเพื่อรับจำนวนเงินแล้วคลิก "ฉันต้องการเงินกู้"',
    '恭喜！借款成功 到账至银行卡': 'ยินดีด้วย เงินกู้ถูกโอนเข้าบัตรธนาคารเรียบร้อยแล้ว',
    '申请的门槛高吗？': 'เกณฑ์การสมัครสูงไหม?',
    '提供本人身份证，本人银行卡。年龄在18岁以上的注册用户，均可申请': 'ระบุบัตรประจำตัวประชาชนของคุณ ผู้ใช้ที่ลงทะเบียนอายุมากกว่า 18 ปีสามารถสมัครได้',
    '借款后可以多久到账？': 'ได้รับเงินตอนไหนหลังจากยืมเงิน?',
    '通过审核后最快三分钟到账': 'หลังจากผ่านการตรวจสอบแล้ว  ได้รับเงิน3นาทีและออกให้กับบัตรธนาคาร บางครั้งอาจล่าช้า โปรดดูที่การแจ้งเตือนบัตรธนาคารที่ได้รับสำหรับเวลาที่มาถึงโดยเฉพาะ',
    '什么时候可以还款?': 'สามารถชำระคืนได้เมื่อไร?',
    '可以进行提前还款': 'ผ่อนชำระก่อนกำหนดได้ ยืมวันนี้จ่ายคืนพรุ่งนี้ ผ่อนคิดดอกเบี้ยถึงวันเท่านั้น',
    '时间': '(เวลาให้บริการ: 9:00 น. ถึง 21:00 น. ทุกวัน)',
    '如需联系我们请拨打客服热线': 'หากคุณต้องการติดต่อเรา กรุณาโทรติดต่อสายด่วนบริการลูกค้า',
    '客服': 'บริการลูกค้า',
    '下载App': 'ดาวน์โหลดAPP',
    '官方Facebook': 'Facebookทางการ',
    'Ios客户端': 'ฝ่ายบริการลูกค้า IOS',
    '安卓客户端': 'ฝ่ายบริการลูกค้า Android',
    '推荐与服务': 'คำแนะนำและบริการ',
    '服务号': 'หมายเลขบริการ',
    '官方邮箱': 'กล่องจดหมายอย่างเป็นทางการ',
    '地址': 'ที่อยู่',
    '关于我们': 'เกี่ยวกับเรา',
    '官方Line': 'ทางการline',
    '查看更多问题': 'ดูคำถามเพิ่มเติม',
    "安全": "ปลอดภัย",
    '放心': 'มั่นใจ',
    '保障': 'มั่นคง',
    "快速审批 灵活借还": "อนุมัติไว ยืมคืนที่ยืดหยุ่น",
    "我们的优势": "ข้อได้เปรียบของเรา",
    "低利息": "ดอกเบี้ยต่ำ",
    "0抵押0担保额度有保障": "0 จำนอง 0 วงเงินค้ำรับประกัน",
    "申请流程": "ขั้นตอนการสมัคร",
    "官方": "ทางการ",
    "隐私政策": 'นโยบายความเป็นส่วนตัว',
    "电话": "โทรศัพท์",
    "邮箱": "อีเมล",
    "借钱来这!": 'ยืมเงินมาที่นี่ !',
    "立即申请": "สมัครทันที",
    "信用生活": "เครดิตชีวิต",
    "个人消费": "การบริโภคส่วนบุคคล",
    "最高可贷20000申请便捷": "สินเชื่อสูงถึง 20,000 สมัครง่าย",
    "最高可贷20000快速审批": "อนุมัติเร็ว สินเชื่อสูงถึง 20,000",
    "最高可贷20000极速审批": "อนุมัติเร็ว สินเชื่อสูงถึง 20,000",
    "医美": "ความงามทางการแพทย์",
    "口腔": 'ช่องปาก',
    "全球领先的贷款服务": "บริการสินเชื่อชั้นนำระดับโลก",
    "最高可借20万": "วงเงินกู้สูงสุด 200,000",
    "审批快": "อนุมัติรวดเร็ว",
    "手机申请 自动审批": "สมัครผ่านมือถือ อนุมัติอัตโนมัติ",
    "还款灵活": "การชำระคืนที่ยืดหยุ่น",
    "多种期数可选": "ตัวเลือกงวดหลายแบบ",
    "填写手机号": "กรอกหมายเลขโทรศัพท์",
    "线上申请": "สมัครออนไลน์",
    "获取额度": "รับวงเงิน",
    "提现到账": "ถอนเงินเข้าบัญชี",
    "最快1分钟到账": "เร็วที่สุดใน 1 นาที",
    "1、申请贷款时，银行流水不够怎么办": "1. จะทำอย่างไรถ้าไม่มีรายการธนาคารเพียงพอเมื่อขอสินเชื่อ?",
    "2、申请贷款失败原因主要有哪些": "2. สาเหตุหลักที่ทำให้การขอสินเชื่อล้มเหลวมีอะไรบ้าง?",
    "通过审核后最快3分钟到账，发放到银行卡。但因网络原因，到账时间可能会延迟，具体到账时间请以是款银行卡通知为准。": "หลังจากการอนุมัติ เงินอาจเข้าบัญชีภายใน 3 นาที แต่เนื่องจากปัญหาเครือข่าย เวลาในการเข้าบัญชีอาจล่าช้า กรุณาตรวจสอบเวลาที่แน่นอนจากการแจ้งเตือนของธนาคารของคุณ",
    "3、借款后可以多久到账？": "3. ใช้เวลานานเท่าไหร่หลังจากการขอกู้เงิน?",
    "可以进行提前还款，今天借，明天能还。还款时只计算截止到当天的利息。": "สามารถชำระคืนล่วงหน้าได้ ยืมวันนี้ คืนได้พรุ่งนี้ ดอกเบี้ยจะคำนวณถึงวันที่คืนเงินเท่านั้น",
    "正规": "ที่ถูกต้อง",
    "金融平台": "แพลตฟอร์มการเงิน",
    "权威品牌": "แบรนด์ที่มีอำนาจ",
    "30秒审批": "อนุมัติใน 30 วินาที",
    "高额度": "วงเงินสูง",
    "贷款产品": "ผลิตภัณฑ์เงินกู้",
    "房产贷": "เงินกู้เพื่อที่อยู่อาศัย",
    "商品房，全款房，按揭房，经济适用房": "บ้านสินค้าที่อยู่อาศัย บ้านจ่ายเต็ม บ้านผ่อน บ้านราคาถูก",
    "汽车贷": "เงินกู้รถยนต์",
    "抵押贷，信用贷，按揭车月供放大贷": "เงินกู้จำนำ เงินกู้เครดิต เงินกู้ผ่อนรถ",
    "保单贷": "เงินกู้ตามกรมธรรม์",
    "所有寿险公司保单保单放大贷": "เงินกู้กรมธรรม์จากทุกบริษัทประกันชีวิต",
    "经营贷": "เงินกู้เพื่อการดำเนินธุรกิจ",
    "双周供，循环贷，存抵贷，气球贷": "เงินกู้สองสัปดาห์ เงินกู้หมุนเวียน เงินกู้ฝาก-ถอน เงินกู้ลูกโป่ง",
    "最高获20万": "สูงสุด 200,000 บาท",
    "贷款帮助": "ความช่วยเหลือด้านเงินกู้",
    "找贷款麻烦？": "มีปัญหาในการหาเงินกู้?",
    "我们来帮忙": "เราช่วยคุณได้",
    "马上筛选": "เลือกทันที",
    "1分钟出额度？": "รู้วงเงินใน 1 นาที?",
    "看看贷多少": "ดูว่าคุณกู้ได้เท่าไหร่",
    "立即评估": "ประเมินทันที",
    "怎么贷最划算？": "จะกู้ยังไงให้คุ้มที่สุด?",
    "看看贷最低": "ดูอัตราดอกเบี้ยต่ำสุด",
    "马上测评": "ประเมินตอนนี้",

    "专业广告代运营投放平台": "แพลตฟอร์มการจัดการโฆษณามืออาชีพ",
    "精准投放  免费定制方案  一站式代理": "การโฆษณาที่แม่นยำ แผนการที่กำหนดเองฟรี บริการตัวแทนครบวงจร",
    "助你轻松实现商业目标": "ช่วยให้คุณบรรลุเป้าหมายทางธุรกิจได้อย่างง่ายดาย",
    "立即体验": "ลองใช้ทันที",
    "公司简介": "แนะนำบริษัท",
    "专注为国内外客户提供海外广告投放服务，在Facebook、Google、Tiktok以及Apple Search等海外广告媒体渠道及其附属的相应互联网络平台投放广告。": "มุ่งเน้นให้บริการโฆษณาต่างประเทศแก่ลูกค้าทั้งในประเทศและต่างประเทศ บนช่องทางสื่อโฆษณาต่างประเทศ เช่น Facebook, Google, Tiktok และ Apple Search และแพลตฟอร์มอินเทอร์เน็ตที่เกี่ยวข้อง",
    "提供互联网游戏开发、经营业务系统开发等互联网技术软件开发服务及信息技术咨询服务。": "ให้บริการพัฒนาซอฟต์แวร์เทคโนโลยีอินเทอร์เน็ต เช่น การพัฒนาเกมออนไลน์ การพัฒนาระบบการจัดการธุรกิจ และบริการให้คำปรึกษาทางเทคโนโลยี",
    "企业愿景": "วิสัยทัศน์องค์กร",
    "全球广告运营投放引领者": "ผู้นำในการจัดการโฆษณาทั่วโลก",
    "我们的使命": "พันธกิจของเรา",
    "为万千用户提供流量变现支持服务": "ให้บริการสนับสนุนการสร้างรายได้จากการเข้าชมแก่ผู้ใช้นับพันคน",
    "发展目标": "เป้าหมายการพัฒนา",
    "打造数据化广告流平台，实现低成本获客": "สร้างแพลตฟอร์มโฆษณาข้อมูลเพื่อให้ได้ลูกค้าด้วยต้นทุนต่ำ",
    "了解我们": "รู้จักเรา",
    "把您的广告展现给精准用户": "แสดงโฆษณาของคุณให้ผู้ใช้ที่แม่นยำเห็น",
    "专业团队规模": "ขนาดทีมมืออาชีพ",
    "累计服务用户": "จำนวนผู้ใช้ที่ให้บริการสะสม",
    "覆盖行业": "อุตสาหกรรมที่ครอบคลุม",
    "深耕广告投放": "ลงลึกในโฆษณา",
    "行动号召": "เรียกร้องให้ดำเนินการ",
    "关于我们，您可以用更方便的方式联系。希望能帮到您！": "เกี่ยวกับเรา คุณสามารถติดต่อเราได้อย่างสะดวกสบาย หวังว่าจะสามารถช่วยคุณได้!",
    "办公环境": "สภาพแวดล้อมการทำงาน",
    "我们是最好的": "เราดีที่สุด",
    "10年+":"10 ปี+",
}